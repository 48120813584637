// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

:root {
  --ion-color-warning-tint: #ffc40980;

  /** App Primary **/
  --ion-color-app-primary: #0468bf;
  --ion-color-app-primary-rgb: 4, 104, 191;
  --ion-color-app-primary-contrast: #ffffff;
  --ion-color-app-primary-contrast-rgb: 255, 255, 255;
  --ion-color-app-primary-shade: #045ca8;
  --ion-color-app-primary-tint: #0468bf80;

  /** App Secondary **/
  --ion-color-app-secondary: #0477bf;
  --ion-color-app-secondary-rgb: 4, 119, 191;
  --ion-color-app-secondary-contrast: #ffffff;
  --ion-color-app-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-app-secondary-shade: #0469a8;
  --ion-color-app-secondary-tint: #0477bf80;

  /** App Tertiary **/
  --ion-color-app-danger: #f54f29;
  --ion-color-app-danger-rgb: 245, 79, 41;
  --ion-color-app-danger-contrast: #ffffff;
  --ion-color-app-danger-contrast-rgb: 255, 255, 255;
  --ion-color-app-danger-shade: #d84624;
  --ion-color-app-danger-tint: #f54f2980;

  /** App Success **/
  --ion-color-app-success: #47bb82;
  --ion-color-app-success-rgb: 71, 187, 130;
  --ion-color-app-success-contrast: #ffffff;
  --ion-color-app-success-contrast-rgb: 0, 0, 0;
  --ion-color-app-success-shade: #3ea572;
  --ion-color-app-success-tint: #47bb8280;
}

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import '@ionic/angular/css/palettes/dark.system.css';

.ion-color-app-primary {
  --ion-color-base: var(--ion-color-app-primary);
  --ion-color-base-rgb: var(--ion-color-app-primary-rgb);
  --ion-color-contrast: var(--ion-color-app-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-app-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-app-primary-shade);
  --ion-color-tint: var(--ion-color-app-primary-tint);
}

.ion-color-app-secondary {
  --ion-color-base: var(--ion-color-app-secondary);
  --ion-color-base-rgb: var(--ion-color-app-secondary-rgb);
  --ion-color-contrast: var(--ion-color-app-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-app-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-app-secondary-shade);
  --ion-color-tint: var(--ion-color-app-secondary-tint);
}

.ion-color-app-danger {
  --ion-color-base: var(--ion-color-app-danger);
  --ion-color-base-rgb: var(--ion-color-app-danger-rgb);
  --ion-color-contrast: var(--ion-color-app-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-app-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-app-danger-shade);
  --ion-color-tint: var(--ion-color-app-danger-tint);
}

.ion-color-app-success {
  --ion-color-base: var(--ion-color-app-success);
  --ion-color-base-rgb: var(--ion-color-app-success-rgb);
  --ion-color-contrast: var(--ion-color-app-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-app-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-app-success-shade);
  --ion-color-tint: var(--ion-color-app-success-tint);
}

ion-router-outlet {
  background-color: var(--ion-color-light);
}

ion-toolbar {
  --min-height: 50px;
  --padding-start: 15px;
  --padding-end: 15px;

  fa-icon {
    font-size: 20px;
  }
}

.label-text {
  color: var(--ion-color-medium);
}

ion-loading.custom-loading {
  --spinner-color: var(--ion-color-medium);
  --backdrop-opacity: 0.6;
  color: var(--ion-color-medium);
}

.alert-app-messages .alert-head {
  margin: 0 !important;
  padding: 0 !important;
}

.alert-app-messages .alert-title {
  padding: 10px;
}

.alert-app-messages .alert-sub-title {
  padding: 10px 10px 0 10px;
}

.alert-app-messages {
  .alert-title {
    font-size: 22px;
    color: var(--ion-color-light);
    margin: 0;
  }

  .alert-message {
    color: var(--ion-color-medium);
    padding-top: 10px;
  }

  textarea {
    min-height: 100px !important;
    color: var(--ion-color-medium);
    margin-top: 0 !important;
  }
}

.refresher-refreshing-text,
.refresher-pulling-icon {
  color: var(--ion-color-medium) !important;
}

.alert-app-messages.alert-info-message .alert-title {
  background-color: var(--ion-color-app-primary);
}

.alert-app-messages.alert-warning-message .alert-title {
  background-color: var(--ion-color-warning);
}

.alert-app-messages.alert-error-message .alert-title {
  background-color: var(--ion-color-app-danger);
}

ion-input {
  height: 65px;

  .input-wrapper {
    padding: 0;

    .native-wrapper {
      background-color: var(--ion-color-light);
      margin-top: 10px;
      border-radius: 5px;

      input {
        padding-left: 10px;
      }
    }
  }
}

ion-textarea {
  .native-wrapper {
    .native-textarea {
      background-color: var(--ion-color-light);
      padding: 10px !important;
      border-radius: 5px;
    }
  }
}

ion-fab-button::part(native) {
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

ion-modal {
  --background: transparent !important;
}

app-time-formcontrol {
  .ngb-tp {
    max-width: 100px;
  }
}

ngb-datepicker {
  width: 100% !important;
  border: none !important;

  .ngb-dp-header {
    background-color: transparent !important;
  }

  .ngb-dp-content {
    width: 100% !important;

    .ngb-dp-month {
      width: 100%;

      .ngb-dp-weekdays {
        background-color: transparent !important;
        border: none !important;
        margin-top: 10px;
        margin-bottom: 10px;

        .ngb-dp-weekday {
          color: var(--ion-color-medium);
          font-style: normal;
        }
      }
    }

    .ngb-dp-week {
      justify-content: center;

      .ngb-dp-day,
      .ngb-dp-weekday,
      .ngb-dp-week-number {
        height: 2.5rem;
        width: 2.5rem;
      }

      .ngb-dp-week-number {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ngb-dp-day {
        color: var(--ion-color-dark);

        .disabled {
          opacity: 0.5;
        }

        .today {
          background-color: var(--ion-color-medium);
          color: var(--ion-color-light);
        }

        .custom-day {
          text-align: center;
          padding: 0.185rem 0.25rem;
          height: 2.5rem;
          width: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          // border-radius: 5px;
        }

        .focused {
          background-color: var(--bs-tertiary-bg);
          outline: 1px solid var(--bs-border-color);
        }

        .selected {
          background-color: var(--ion-color-app-primary);
          color: var(--ion-color-light);
        }
      }

      .ngb-dp-week-number {
        color: var(--ion-color-medium) !important;
        font-style: normal;
      }
    }
  }
}

*::-webkit-scrollbar {
  //display: none;
}

// Hide Ms Edge password reveal button
::-ms-reveal {
  display: none;
}

@media (min-width: 992px) {
  ion-split-pane {
    --side-max-width: 250px;
    --border: 1px solid #d9d9d9;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
